import React from 'react';

import TikrayHeader from '../Customs/TikrayHeader';
import TikrayFooter from '../Customs/TikrayFooter';

const Layout = ({ children }) => {
    

    return (
        <>
            <TikrayHeader />
            <main>{children}</main>
            <TikrayFooter />
        </>
    )
}

export default Layout;